import React from "react";

const Contact = () =>{
    return(
        <section id='contact'styles={styles.section} >
            <h4>Contact Me</h4>
            <button >click me</button>
            <div>
                <p>    </p>
            </div>
        </section>
    );
};
const styles ={

}

export default Contact;